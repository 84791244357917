import { Content } from "@prismicio/client";
import { SliceComponentProps } from "@prismicio/react";
import { Image } from "@/lib/imgproxy";
import { Carousel, CarouselContent, CarouselItem } from "@/components/Carousel";
import Autoplay from "embla-carousel-autoplay";

/**
 * Props for `VenueCarouselSlice`.
 */
export type VenueCarouselSliceProps =
    SliceComponentProps<Content.VenueCarouselSliceSlice>;

/**
 * Component for "VenueCarouselSlice" Slices.
 */
const VenueCarouselSlice = ({
    slice,
}: VenueCarouselSliceProps): JSX.Element => (
    <section
        data-slice-type={slice.slice_type}
        data-slice-variation={slice.variation}
        className="flex flex-col gap-5 bg-white py-20"
    >
        <span
            data-show={slice.primary.title_1 ? "true" : "false"}
            className="typography-overline-lg text-center text-blue-grey data-[show=false]:hidden"
        >
            {slice.primary.title_1}
        </span>
        <Carousel
            opts={{
                align: "start",
                loop: true,
                watchDrag: false,
            }}
            plugins={[Autoplay({ delay: 1500 })]}
            className="pointer-events-none h-fit w-full flex-col gap-5"
        >
            <CarouselContent className="ml-0 mr-0">
                {slice.primary.venue
                    .filter((v) => !!v.img.url)
                    .map((v) => (
                        <CarouselItem
                            key={v.img.url ?? ""}
                            className="!lg:pr-12 !pr-12"
                        >
                            <div className="relative aspect-square size-[140px]">
                                <Image
                                    alt={v.img.alt ?? ""}
                                    src={v.img.url ?? ""}
                                    objectFit="contain"
                                    height="140px"
                                    width="140px"
                                />
                            </div>
                        </CarouselItem>
                    ))}
            </CarouselContent>
        </Carousel>
        <span
            data-show={slice.primary.title_2 ? "true" : "false"}
            className="typography-h4 text-center text-blue-grey data-[show=false]:hidden"
        >
            {slice.primary.title_2}
        </span>
    </section>
);

export default VenueCarouselSlice;
